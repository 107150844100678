/**
 * @name: 旅游订单接口
 * @author: itmobai
 * @date: 2023-06-01 14:21
 * @description：旅游订单接口
 * @update: 2023-06-01 14:21
 */
import {get, postJ, postW} from "@/request";
import {ITravelOrder, ITravelOrderParam} from "@/apis/order/travel/types";
import {IPageRes} from "@/apis/page";

/**
 * 旅游订单分页查询
 * @param params 查询参数
 */
export const orderQueryTourOrderByPageApi = (params: ITravelOrderParam) => get<IPageRes<ITravelOrder[]>>("/golf/order/queryTourOrderByPage", params)
/**
 * 旅游订单导出
 * @param params 查询参数
 */
export const orderTourOrderExportApi = (params: ITravelOrderParam) => get("/golf/order/tourOrderExport", params, 'blob')
/**
 * 完成订单
 * @param orderId 订单id
 */
export const orderFinalizeTourOrderApi = (orderId: string) => postW("/golf/order/finalizeTourOrder?orderId=" + orderId, {})
