
/**
 * @name: 旅游订单管理
 * @author: itmobai
 * @date: 2023-06-01 14:23
 * @description：旅游订单管理
 * @update: 2023-06-01 14:23
 */
import {Component, Vue} from "vue-property-decorator";
import {ICrudOption} from "@/types/m-ui-crud";
import {ITravelOrder, ITravelOrderParam} from "@/apis/order/travel/types";
import {deepCopy, exportFile} from "@/utils/common";
import {orderFinalizeTourOrderApi, orderQueryTourOrderByPageApi, orderTourOrderExportApi} from "@/apis/order/travel";

@Component({})
export default class ITravelOrderPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格总数
  tableTotal: number = 0;
  // 表格数据
  tableData: ITravelOrder[] = []
  // 表单
  modelForm: Partial<ITravelOrder> = {}
  // 查询参数
  queryParam: ITravelOrderParam = {
    page: 1,
    limit: 10
  }
  // crudOption
  option: ICrudOption = {
    labelWidth: '120px',
    column: [
      {
        "label": "订单编号",
        "prop": "orderSn",
        "align": "left",
        "width": "180",
        "search": true
      },
      {
        "label": "旅团名称",
        "prop": "tourName",
        "search": true,
        "align": "center",
        "overHidden": true,
        "width": 150
      },
      {
        "label": "下单客户",
        "prop": "orderCustom",
        "align": "center",
        "overHidden": true,
        "search": true,
        "width": 150
      },
      {
        "label": "地区",
        "prop": "tourTypes",
        "overHidden": true,
        "search": true,
        "align": "center",
        "type": "select",
        "dicData": [
          {
            "label": "海内",
            "value": 1
          },
          {
            "label": "海外",
            "value": 2
          },
          {
            "label": "定制",
            "value": 3
          }
        ]
      },
      {
        "label": "地点标签",
        "prop": "tourLocationName",
        "align": "center",
        "width": "150",
        "overHidden": false
      },
      {
        "label": "旅团售价",
        "prop": "tourPrice",
        "align": "center",
        "width": "150",
        "overHidden": false
      },
      {
        "label": "订单积分",
        "prop": "integral",
        "align": "center",
        "width": "150"
      },
      {
        "label": "支付方式",
        "prop": "payWay",
        "align": "center",
        "width": "150",
        "type": "select",
        "search": true,
        "dicData": [
          {
            "label": "余额",
            "value": 1
          },
          {
            "label": "微信支付",
            "value": 2
          }
        ]
      },
      {
        "label": "订单状态",
        "prop": "tourOrderStatus",
        "align": "center",
        "width": "150",
        "search": true,
        "type": "select",
        "dicData": [
          {
            "label": "待支付",
            "value": 1,
            "color": "#F56C6C"
          },
          {
            "label": "待使用",
            "value": 2,
            "color": "#409EFF"
          },
          {
            "label": "已完成",
            "value": 3,
            "color": "#67C23A"
          },
          {
            "label": "已取消",
            "value": 4,
            "color": "#F56C6C"
          }
        ]
      },
      {
        "label": "下单时间",
        "prop": "payTime",
        "align": "center",
        "width": "180",
        "type": "daterange",
        "search": true
      }
    ]
  }

  /**
   * 导出excel
   */
  exportExcel () {
    const query: ITravelOrderParam = deepCopy(this.queryParam)
    if (query.payTime && query.payTime.length === 2) {
      query.payTimeStart = query.payTime[0]
      query.payTimeEnd = query.payTime[1]
    } else {
      query.payTimeStart = ""
      query.payTimeEnd = ""
    }
    delete query.payTime
    orderTourOrderExportApi(query).then(e => {
      exportFile(e, "旅游订单.xlsx")
    })
  }

  /**
   * 完成订单
   * @param orderId 订单id
   */
  completeOrder (orderId: string) {
    this.$confirm('是否完成订单？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      orderFinalizeTourOrderApi(orderId).then(e => {
        if (e) {
          this.$message.success("操作成功!")
          this.getList()
        }
      })
    }).catch(() => {
    });
  }

  getList () {
    this.tableLoading = true
    const query: ITravelOrderParam = deepCopy(this.queryParam)
    if (query.payTime && query.payTime.length === 2) {
      query.payTimeStart = query.payTime[0]
      query.payTimeEnd = query.payTime[1]
    } else {
      query.payTimeStart = ""
      query.payTimeEnd = ""
    }
    delete query.payTime
    orderQueryTourOrderByPageApi(query).then(e => {
      this.tableLoading = false
      this.tableData = e.list
      this.tableTotal = e.total
    })
  }

  created () {
    this.getList()
  }
}
